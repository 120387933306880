import * as Sentry from '@sentry/react';
import { Suspense, lazy } from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { RouterErrorBoundaryFallback500Redirect } from '../components/RouterErrorBoundary/RouterErrorBoundary';
import { LoadingGeneric } from '../components/Loading/LoadingGeneric';

import { RedirectTo404 } from './guards/RedirectTo404';
import { RACRouterProvider } from './providers/RACRouterProvider';

const Error404 = lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage'));
const Error500 = lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage'));
const EmissionsClarity = lazy(() => import('../pages/public/share/emissions/Clarity'));
const EmissionsAccuracy = lazy(() => import('../pages/public/share/emissions/accuracy/Accuracy'));
const EmissionsBreakdown = lazy(() => import('../pages/public/share/emissions/breakdown/Breakdown'));
const EmissionsSummary = lazy(() => import('../pages/public/share/emissions/summary/Summary'));
const EmissionsReport = lazy(() => import('../pages/public/share/emissions/reports/Report'));
const DataCollectionRequest = lazy(() => import('../pages/public/share/data-collections/Request'));

const sentryCreateBrowserRouter =
  process.env.NODE_ENV === 'production' ? Sentry.wrapCreateBrowserRouter(createBrowserRouter) : createBrowserRouter;

const router = sentryCreateBrowserRouter([
  {
    element: (
      <RACRouterProvider>
        <Suspense fallback={<LoadingGeneric />}>
          <Outlet />
        </Suspense>
      </RACRouterProvider>
    ),
    ErrorBoundary: RouterErrorBoundaryFallback500Redirect,
    children: [
      {
        path: 'test?',
        children: [
          {
            path: 'emissions/:token',
            children: [
              {
                path: 'clarity',
                element: <EmissionsClarity />,
              },
              {
                path: 'accuracy',
                element: <EmissionsAccuracy />,
              },
              {
                path: 'breakdown',
                element: <EmissionsBreakdown />,
              },
              {
                path: '',
                element: <EmissionsSummary />,
              },
            ],
          },
          {
            path: 'reports/:token',
            element: <EmissionsReport />,
          },
          {
            path: 'data-collections/inbound/:requestId',
            element: <DataCollectionRequest />,
          },
        ],
      },
      {
        path: '404',
        element: <Error404 />,
      },
      {
        path: '500',
        element: <Error500 />,
      },
      {
        path: '*',
        element: <RedirectTo404 />,
      },
    ],
  },
]);

export function RouterShare() {
  return <RouterProvider router={router} fallbackElement={null} />;
}
