import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { matchRoutes, useLocation, useNavigationType, createRoutesFromChildren } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// don't import components before this line otherwise style precedence will break
import './styles/index.css';
import { LastResortError } from './components/Errors/LastResort/LastResortError';
import { LastResortLayout } from './layouts/LastResort/LastResort';
import { RouterShare } from './routers/router_share';
import { ProviderShare } from './providers/provider_share';

// Sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SHARE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || ''),
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary
    fallback={
      <LastResortLayout>
        <LastResortError />
      </LastResortLayout>
    }
  >
    <ProviderShare>
      <RouterShare />
    </ProviderShare>
  </Sentry.ErrorBoundary>,
);
